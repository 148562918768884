import { SelectionGroup } from "@lu/muscat-analytics-library/dist/model/aggregate-job";
import { Question } from "@muscat/types";
import React, { FunctionComponent, useCallback } from "react";
import { Button } from "react-bootstrap";
import { SettingSelectionGroup } from "./selection-group";
import { useTranslation } from "react-i18next";
import { ButtonsContainer } from "../../../../parts/buttons-container";
import { ErrorObject } from "@lu/validator";
import { getErrorObject } from "../../../../../lib/error";

export type SettingSelectionGroupsProps = {
	error?: ErrorObject;
	selectionGroups: SelectionGroup[];
	questions: Question[];
	onAddSelectionGroup: () => void;
	onDeleteSelectionGroup: (targetIndex: number) => void;
	onChange: <T extends keyof SelectionGroup>(targetName: string, name: T, value: SelectionGroup[T]) => void;
};

export const SettingSelectionGroups: FunctionComponent<SettingSelectionGroupsProps> = React.memo(
	({ error, selectionGroups, questions, onAddSelectionGroup, onDeleteSelectionGroup, onChange }) => {
		const { t } = useTranslation();

		const getSelectionError = useCallback((index: number) => getErrorObject([index.toString()], error), [error]);

		const handleChange = useCallback(
			<T extends keyof SelectionGroup>(targetName: string, name: T, value: SelectionGroup[T]) => {
				onChange(targetName, name, value);
			},
			[onChange]
		);
		return (
			<div className="aggregate-editor-page__group-selection">
				{selectionGroups.map((selectionGroup, index) => (
					<SettingSelectionGroup
						error={getSelectionError(index)}
						index={index}
						key={`selection-group-${selectionGroup.quename}-${index}`}
						selectionGroup={selectionGroup}
						questions={questions}
						onChange={handleChange.bind(undefined, selectionGroup.name)}
						onDelete={onDeleteSelectionGroup}
					/>
				))}
				<ButtonsContainer>
					<Button block variant="outline-primary" onClick={onAddSelectionGroup}>
						<i className="bi-plus" />
						<span>{t("aggregate_editor_page.add_selection_group")}</span>
					</Button>
				</ButtonsContainer>
			</div>
		);
	}
);
