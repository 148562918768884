import React, { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";

import { endpoint } from "../../routes/endpoints";
import { internalApiEndpoint } from "../../../../server/router/api/internal/endpoint";
import { EnqueteEditor } from "@bleu/supcolo-editor";
import { templates } from "../../lib/templates";
import Page from "../../components/parts/page";

type Props = {};

export const EnqueteEditorContainer: FunctionComponent<Props> = React.memo(() => {
	const { t } = useTranslation();

	const history = useHistory();

	const { id } = useParams<{ id?: string }>();

	const location = history.location.pathname;

	const type = location === endpoint.createEnquete ? "create_enquete" : "edit_enquete";

	useEffect(() => {
		document.title = `${t(`page_title.${type}`)} - ${t("page_title.site_name")}`;
	}, [id, type]);

	return (
		<Page fluid className="enquete-editor-page">
			<EnqueteEditor
				apiEndpoints={internalApiEndpoint}
				attributeQuestionTemplates={templates}
				disabledQuestionTypes={["FI"]}
				jobId={id}
				layout={{
					generalOptions: { answerReturn: "disabled", enqueteTitle: "disabled" },
					openType: "disabled",
					scripts: "enabled",
				}}
				maxAttributeQuestionsCount={5}
				webEndpoints={{
					defaultBaseUrl: process.env.MUSCAT_APP_URL,
					debug: `:baseUrl/enquete`,
					enquete: `:baseUrl/enquete`,
					preview: `:baseUrl/preview`,
				}}
				variants={{
					background: "background",
					controlPrimary: "secondary",
					controlSecondary: "gray",
					secondary: "gray",
				}}
			/>
		</Page>
	);
});
