import classNames from "classnames";
import React, { useMemo } from "react";
import { AxisQuename, SelectionGroup, TargetQuestion } from "@lu/muscat-analytics-library/dist/model/aggregate-job";
import { Choice, NormalQuestion, Question } from "@muscat/types/dist/models/question";
import { Button } from "react-bootstrap";
import { makeTargetLabel, replaceTag } from "../../../../../lib/common";
import { AggregateTargetViewer } from "./view";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../parts/checkbox";
import { ButtonsContainer } from "../../../../parts/buttons-container";

export type AggregateTargetProps = {
	questions: Question[];
	selectionGroups: SelectionGroup[];
	targetQuestions: TargetQuestion[]; // 対象の設問
	axis: AxisQuename[][]; // 集計軸
	invalid?: boolean;
	onChangeTargetQuestions: (targetQuestions: TargetQuestion[]) => void;
	onChangeAxis: (axis: AxisQuename[][]) => void;
};

export const AggregateTarget: React.FC<AggregateTargetProps> = React.memo(
	({ questions, selectionGroups, targetQuestions, axis, invalid, onChangeTargetQuestions, onChangeAxis }) => {
		const { t } = useTranslation();
		const [targetQuename, setTargetQuename] = React.useState<string>();

		const quenames = useMemo(() => targetQuestions.map(({ quename }) => quename), [targetQuestions]);

		const qMap = useMemo(() => {
			const tmp = questions.reduce((a, b) => {
				if (b.type !== "S" && b.type !== "M" && b.type !== "MT") return a;
				const choices = b.choiceGroups.reduce(
					(a, b) => [
						...a,
						...b.choices.map((choice) => {
							return { ...choice, text: replaceTag(choice.text) };
						}),
					],
					[]
				);
				const tmp: { choices: Choice[]; childQuestion?: NormalQuestion[] } = {
					choices,
				};
				if (b.type === "MT") {
					const childQuestion: NormalQuestion[] = b.childQuestionGroups
						.reduce(
							(a, b) => [
								...a,
								...b.questions.map((question) => {
									return { ...question, quetitle: replaceTag(question.quetitle) };
								}),
							],
							[]
						)
						.filter((question) => question.type === "S" || question.type === "M");
					if (!childQuestion.length) return a;
					tmp.childQuestion = childQuestion;
				}
				a.set(b.quename, tmp);
				return a;
			}, new Map<string, { choices: Choice[]; childQuestion?: NormalQuestion[] }>());
			for (const selectionGroup of selectionGroups) {
				tmp.set(selectionGroup.name, {
					choices: selectionGroup.grouping.map((g, index) => ({
						text: g.label,
						value: index + 1,
					})),
				});
			}
			return tmp;
		}, [questions, selectionGroups]);

		const changeTargetQuestions = React.useCallback(
			(quename: string, checked: boolean) => {
				onChangeTargetQuestions(
					checked
						? [...targetQuestions, { quename }]
						: targetQuestions.filter((targetQuestion) => targetQuestion.quename !== quename)
				);
			},
			[targetQuestions, onChangeTargetQuestions]
		);

		const changeAxis = React.useCallback(
			(index: number, checked: boolean, quename: string, childQuename?: string) => {
				onChangeAxis(
					axis.map((ax, axIndex) => {
						if (index !== axIndex) return ax;
						return checked
							? [...ax, { quename, childQuename }]
							: ax.filter((a) => a.quename !== quename || a.childQuename !== childQuename);
					})
				);
			},
			[axis, onChangeAxis]
		);

		// const addAxis = React.useCallback(() => onChangeAxis([...axis, []]), [axis, onChangeAxis]);

		// const deleteAxis = React.useCallback(
		// 	(index: number) => onChangeAxis(axis.filter((_, axIndex) => index !== axIndex)),
		// 	[axis, onChangeAxis]
		// );

		const onAllCheckedChange = React.useCallback(() => {
			if (targetQuestions.length) return onChangeTargetQuestions([]);
			const quenamesCheckeds = questions
				.filter(({ quename }) => {
					if (!qMap.has(quename)) return false;
					return true;
				})
				.map(({ quename }) => ({ quename }));
			const selectionGroupCheckeds = selectionGroups.map(({ name }) => ({ quename: name }));
			onChangeTargetQuestions([...quenamesCheckeds, ...selectionGroupCheckeds]);
		}, [targetQuestions, questions, qMap, onChangeTargetQuestions, selectionGroups]);
		const onClick = React.useCallback((quename: string) => setTargetQuename(quename), []);

		return (
			<div
				className={classNames("aggregate-editor-page__target", { "aggregate-editor-page__target--invalid": !!invalid })}
			>
				<ButtonsContainer>
					<Button onClick={onAllCheckedChange}>{t("aggregate_editor_page.check_all")}</Button>
					{/* <Button onClick={addAxis}>{t("aggregate_editor_page.add_axis")}</Button> */}
				</ButtonsContainer>
				<div className={"aggregate-editor-page__target__area"}>
					<div className={"aggregate-editor-page__target__area__questions-container"}>
						<table className={"aggregate-editor-page__target__area__questions"}>
							<thead>
								<tr>
									<th>
										<span>{t("aggregate_editor_page.target_question_title")}</span>
									</th>
									{axis.map((_, index) => (
										<th key={`axis-header-${index}`}>
											<span>
												{t("aggregate_editor_page.axis")}
												{/* <Button variant="white" onClick={() => deleteAxis(index)}>
													<i className="bi-trash" />
												</Button> */}
											</span>
										</th>
									))}
									<th>
										<span>{t("aggregate_editor_page.target_question")}</span>
									</th>
								</tr>
							</thead>
							<tbody>
								{questions.map((question) => (
									<React.Fragment key={`target-question-${question.quename}`}>
										{qMap.has(question.quename) ? (
											<>
												<tr
													onClick={() => onClick(question.quename)}
													{...(question.quename === targetQuename && { className: "tr--focused" })}
												>
													<td>
														<span>
															<Checkbox
																id={question.quename}
																checked={targetQuestions.some(
																	(targetQuestion) => targetQuestion.quename === question.quename
																)}
																onChange={(v) => changeTargetQuestions(question.quename, v.target.checked)}
															/>
														</span>
													</td>
													{axis.map((ax, index) => (
														<td key={`target-question-${question.quename}-${index}`}>
															<span>
																{question.type !== "MT" ? (
																	<Checkbox
																		id={`target-question-${question.quename}-${index}`}
																		checked={ax.some((a) => a.quename === question.quename)}
																		onChange={(v) => changeAxis(index, v.target.checked, question.quename)}
																	/>
																) : (
																	"-"
																)}
															</span>
														</td>
													))}
													<td>{makeTargetLabel(question)}</td>
												</tr>
												{qMap.get(question.quename).childQuestion &&
													qMap.get(question.quename).childQuestion.map((cq) => (
														<tr key={`axis-${question.quename}-${cq.quename}`}>
															<td>
																<span>-</span>
															</td>
															{axis.map((ax, index) => (
																<td key={`axis-${question.quename}-${cq.quename}-${index}`}>
																	<span>
																		<Checkbox
																			id={`axis-${question.quename}-${cq.quename}-${index}`}
																			checked={ax.some(
																				(a) => a.quename === question.quename && a.childQuename === cq.quename
																			)}
																			onChange={(v) =>
																				changeAxis(index, v.target.checked, question.quename, cq.quename)
																			}
																		/>
																	</span>
																</td>
															))}
															<td className="sub-question">{makeTargetLabel(question, cq)}</td>
														</tr>
													))}
											</>
										) : null}
									</React.Fragment>
								))}
								{selectionGroups.map((selectionGroup, sIndex) => {
									return (
										<tr
											key={`target-selection-group-${selectionGroup.name}`}
											onClick={() => onClick(selectionGroup.name)}
											{...(selectionGroup.name === targetQuename && { className: "tr--focused" })}
										>
											<td className={"check"}>
												<span>
													<Checkbox
														id={`${selectionGroup.name}-${sIndex}`}
														checked={quenames.includes(selectionGroup.name)}
														onChange={(v) => changeTargetQuestions(selectionGroup.name, v.target.checked)}
													/>
												</span>
											</td>
											{axis.map((ax, axisIndex) => (
												<td key={`axis-${selectionGroup.name}-${axisIndex}`}>
													<span>
														<Checkbox
															id={`axis-${selectionGroup.name}-${sIndex}-${axis}-${axisIndex}`}
															checked={ax.some((a) => a.quename === selectionGroup.name)}
															onChange={(v) => changeAxis(axisIndex, v.target.checked, selectionGroup.name)}
														/>
													</span>
												</td>
											))}
											<td>{selectionGroup.label}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					<div className={"aggregate-editor-page__target__area__view-container"}>
						{!!targetQuename && <AggregateTargetViewer {...qMap.get(targetQuename)} />}
					</div>
				</div>
			</div>
		);
	}
);
