import { NormalQuestion, Question } from "@muscat/types";

/**
 * タグの削除
 * @param text
 * @returns {boolean}
 */
export const replaceTag = (text: string): string => {
	return text.replace(/<[^>]+\/?>/g, "");
};

export const makeSampleNumber = (n: number): number | string => {
	if (n === 0) return "-";
	return Math.round(n);
};

/**
 * パーセントを生成する。
 * @param {number | undefined} v 値
 * @param {number} base ベースとなる数字
 * @param {number} decimalPoint 小数点
 * @returns {string}
 */
export const makePercent = (v: number | undefined, base: number, decimalPoint = 2): string => {
	if (!v) return "-";
	const tmp = Math.round((v / base) * 100 * Math.pow(10, decimalPoint)) / Math.pow(10, decimalPoint);
	return tmp.toFixed(decimalPoint);
};

export const makeTargetLabel = (question: Question, childQuestion?: NormalQuestion): string => {
	const title = replaceTag((childQuestion ?? question).quetitle ?? (childQuestion ?? question).quelabel);
	return question.quename === title ? question.quename : `${question.quename}${childQuestion?.quename ?? ""}.${title}`;
};
