import { Grouping, SelectionGroup } from "@lu/muscat-analytics-library/dist/model/aggregate-job";
import { Qtype, Question } from "@muscat/types/dist/models/question";
import { Card, Accordion, Button, Form, FormGroup } from "react-bootstrap";
import classNames from "classnames";
import { Option } from "../../../../../../../../server/lib/option";
import { makeTargetLabel, replaceTag } from "../../../../../../lib/common";
import { SelectionGroupItem } from "./item";
import { ValidatableInput } from "../../../../../parts/validatable-input";
import { useTranslation } from "react-i18next";
import { ValidatableSelect } from "../../../../../parts/validatable-select";
import React, { FunctionComponent, useCallback, useMemo } from "react";
import { ButtonsContainer } from "../../../../../parts/buttons-container";
import { Droppable } from "react-beautiful-dnd";
import { ErrorObject } from "@lu/validator";
import { getErrorObject, getErrorText } from "../../../../../../lib/error";

export type SettingSelectionGroupProps = {
	error?: ErrorObject;
	index: number;
	selectionGroup: SelectionGroup;
	questions: Question[];
	onDelete: (index: number) => void;
	onChange: <T extends keyof SelectionGroup>(name: T, value: SelectionGroup[T]) => void;
};

export const SettingSelectionGroup: FunctionComponent<SettingSelectionGroupProps> = React.memo(
	({ error, selectionGroup, questions, onChange, onDelete, index }) => {
		const { t } = useTranslation();

		const [open, setOpen] = React.useState(true);

		const labelInitialzied = useMemo(
			() => !selectionGroup.label && !getErrorText(["label"], error),
			[error, selectionGroup.label]
		);

		const quenameInitialzied = useMemo(
			() => !selectionGroup.quename && !getErrorText(["quename"], error),
			[error, selectionGroup.quename]
		);

		const questionOptions = React.useMemo<Option[]>(() => {
			const options: Option[] = [];
			for (const question of questions) {
				if (question.type === "E" || question.type === "FI" || question.type === "O") continue;
				if (question.type === "MT") {
					for (const cq of question.childQuestionGroups) {
						for (const q of cq.questions) {
							if (q.type === "O") continue;
							options.push({
								value: `${question.quename}${q.quename}`,
								label: `${question.quename}${q.quename}.（${replaceTag(q.quetitle)}）${replaceTag(
									question.quetitle || question.quelabel
								)}`,
							});
						}
					}
				}
				options.push({ value: question.quename, label: makeTargetLabel(question) });
			}
			return options;
		}, [questions]);

		const qType = React.useMemo<Qtype>(() => {
			if (!selectionGroup.quename) return undefined;
			const question = questions.find((question) => question.quename === selectionGroup.quename);
			return question.type;
		}, [questions, selectionGroup]);

		const options = React.useMemo<Option[]>(() => {
			if (!selectionGroup.quename) return [];
			const question = questions.find((question) => question.quename === selectionGroup.quename);
			if (question.type === "M" || question.type === "S" || question.type === "MT") {
				return question.choiceGroups.reduce((a, b) => {
					return [
						...a,
						...b.choices.map(({ value, text }) => ({
							value,
							label: replaceTag(text),
						})),
					];
				}, []);
			}
			return [];
		}, [questions, selectionGroup]);

		const getGroupingError = useCallback(
			(index: number) => getErrorObject(["grouping", index.toString()], error),
			[error]
		);

		const onDeleteSelection = React.useCallback(
			(index: number) => {
				onChange(
					"grouping",
					selectionGroup.grouping.filter((group, gIndex) => {
						return index !== gIndex;
					})
				);
			},
			[onChange, selectionGroup]
		);

		const onAddSelection = React.useCallback(
			(index: number) => {
				const tmp = [...selectionGroup.grouping];
				if (options.length) {
					tmp.splice(index, 0, { label: "", values: [] });
				} else {
					tmp.splice(index, 0, { label: "", from: undefined, to: undefined });
				}
				onChange("grouping", tmp);
			},
			[onChange, selectionGroup, options]
		);

		const onChangeSelection = React.useCallback(
			<K extends keyof Grouping>(index: number, name: K, value: Grouping[K]) => {
				onChange(
					"grouping",
					selectionGroup.grouping.map((group, gIndex) => {
						if (index !== gIndex) return group;
						return { ...group, [name]: value };
					})
				);
			},
			[onChange, selectionGroup]
		);

		const handleClickToggle = useCallback(() => setOpen(!open), [open]);

		const onDeleteSelectionGroup = useCallback(() => onDelete(index), [index]);

		return (
			<Droppable droppableId={`${selectionGroup.name}:${index}`} type={`selection-group:${index}`}>
				{(provided) => (
					<Accordion
						className={classNames("aggregate-editor-page__selection-group", {
							"aggregate-editor-page__selection-group--open": open,
							"aggregate-editor-page__selection-group--invalid": !!error,
						})}
						ref={provided.innerRef}
						defaultActiveKey="0"
						{...provided.droppableProps}
					>
						<Card style={{ overflow: "visible" }}>
							<Accordion.Toggle
								as={Card.Header}
								eventKey="0"
								onClick={handleClickToggle}
								style={{ background: "none", borderBottom: "none" }}
							>
								<div className="aggregate-editor-page__selection-group__header">
									<Button
										className="aggregate-editor-page__selection-group__header__toggle-button"
										variant="white"
										onClick={handleClickToggle}
									>
										<i className="bi-chevron-down" />
									</Button>
									<div className="aggregate-editor-page__selection-group__header__label">{selectionGroup.label}</div>
									<Button variant="white" onClick={onDeleteSelectionGroup}>
										<i className="bi-trash" />
									</Button>
								</div>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="0">
								<Card.Body>
									<FormGroup>
										<Form.Label>{t("aggregate_editor_page.selection_group_name")}</Form.Label>
										<ValidatableInput
											initialized={labelInitialzied}
											required
											value={selectionGroup.label}
											onInput={(v) => onChange("label", v)}
										/>
									</FormGroup>
									<FormGroup>
										<Form.Label>{t("aggregate_editor_page.selection_group_question")}</Form.Label>
										<ValidatableSelect
											initialized={quenameInitialzied}
											required
											value={selectionGroup.quename}
											options={questionOptions}
											onChange={(v) => onChange("quename", v as string)}
										/>
									</FormGroup>
									{selectionGroup.grouping.map((group, gIndex) => (
										<SelectionGroupItem
											error={getGroupingError(gIndex)}
											selectionIndex={index}
											key={`group-${selectionGroup.name}-${gIndex}`}
											index={gIndex}
											selectionGroup={selectionGroup}
											group={group}
											options={options}
											qType={qType}
											onDeleteSelection={onDeleteSelection}
											onChangeSelection={(name, value) => onChangeSelection(gIndex, name, value)}
										/>
									))}
									{provided.placeholder}
									<ButtonsContainer>
										<Button
											block
											variant="outline-primary"
											onClick={() => onAddSelection(selectionGroup.grouping.length)}
										>
											<i className="bi bi-plus" />
											<span>{t("aggregate_editor_page.add_group_selection_item")}</span>
										</Button>
									</ButtonsContainer>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					</Accordion>
				)}
			</Droppable>
		);
	}
);
