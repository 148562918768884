import React, { useMemo } from "react";
import { SelectionGroup, Grouping } from "@lu/muscat-analytics-library/dist/model/aggregate-job";
import { Qtype } from "@muscat/types/dist/models/question/que-type";
import { Button, FormGroup } from "react-bootstrap";
import { ValidatableInput } from "../../../../../parts/validatable-input";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../../../../..//parts/checkbox";
import { ValidatableSelect } from "../../../../../parts/validatable-select";
import { ButtonsContainer } from "../../../../../parts/buttons-container";
import { Grip } from "../../../../../parts/grip";
import { Draggable } from "react-beautiful-dnd";
import { ErrorObject } from "@lu/validator";
import { getErrorText } from "../../../../../../lib/error";
import { Option } from "../../../../../../../../server/lib/option";

export type SelectionItemProps = {
	error?: ErrorObject;
	selectionIndex: number;
	index: number;
	selectionGroup: SelectionGroup;
	group: Grouping;
	options: Option[];
	qType: Qtype;
	onChangeSelection: <K extends keyof Grouping>(target: K, value: Grouping[K]) => void;
	onDeleteSelection: (index: number) => void;
};

const maOptions: Option[] = [
	{ value: "or", label: "OR" },
	{ value: "and", label: "AND" },
];

export const SelectionGroupItem: React.FC<SelectionItemProps> = React.memo(
	({ error, selectionIndex, index, selectionGroup, group, options, qType, onChangeSelection, onDeleteSelection }) => {
		const { t } = useTranslation();

		const fromInitialzied = useMemo(
			() => (!("from" in group) || !group.from) && !getErrorText(["from"], error),
			[error, group]
		);

		const toInitialzied = useMemo(
			() => (!("to" in group) || !group.to) && !getErrorText(["to"], error),
			[error, group]
		);

		const labelInitialzied = useMemo(() => !group.label && !getErrorText(["label"], error), [error, group.label]);

		const valuesInitialzied = useMemo(
			() => "values" in group && !group.values.length && !getErrorText(["values"], error),
			[error, group]
		);

		const ops = React.useMemo<Option[]>(() => {
			return options.filter((op) => {
				if ("values" in group) {
					if (group.values.includes(Number(op.value))) return true;
					return selectionGroup.grouping.every((group) => {
						if (group.not) return true;
						return "values" in group && !group.values.includes(Number(op.value));
					});
				}
			});
		}, [group, selectionGroup.grouping]);

		return (
			<Draggable key={`${selectionGroup.name}-${index}`} draggableId={`${selectionGroup.name}-${index}`} index={index}>
				{(provided) => (
					<FormGroup
						className="aggregate-editor-page__selection-item"
						ref={provided.innerRef}
						{...provided.draggableProps}
					>
						<Grip {...provided.dragHandleProps} />
						<div className="aggregate-editor-page__selection-item__number">{index + 1}</div>
						<div className="aggregate-editor-page__selection-item__name">
							<ValidatableInput
								initialized={labelInitialzied}
								required
								placeholder={t("aggregate_editor_page.selection_group_item_name")}
								value={group.label}
								onInput={(v) => onChangeSelection("label", v)}
							/>
							<Checkbox
								id={`${selectionIndex}-${index}-not`}
								label={t("aggregate_editor_page.selection_group_item_not")}
								checked={group.not}
								onChange={(e) => onChangeSelection("not", e.target.checked)}
							/>
						</div>
						{qType === "M" && (
							<div className="aggregate-editor-page__selection-item__and">
								<ValidatableSelect
									options={maOptions}
									value={group.and ? "and" : "or"}
									onChange={(v) => onChangeSelection("and", v === "and" ? true : false)}
								/>
							</div>
						)}
						{"values" in group ? (
							<div className="aggregate-editor-page__selection-item__values">
								<ValidatableSelect
									initialized={valuesInitialzied}
									multiple
									required
									options={group.not ? options : ops}
									value={group.values}
									onChange={(v) => onChangeSelection("values" as any, v)}
								/>
							</div>
						) : (
							<div className="aggregate-editor-page__selection-item__range">
								<ValidatableInput
									initialized={fromInitialzied}
									required
									type="number"
									value={(group as any).from}
									onInput={(v) => onChangeSelection("from" as any, parseFloat(v))}
								/>
								<span className="tilda">～</span>
								<ValidatableInput
									initialized={toInitialzied}
									required
									type="number"
									value={(group as any).to}
									onInput={(v) => onChangeSelection("to" as any, parseFloat(v))}
								/>
							</div>
						)}
						<ButtonsContainer>
							<Button variant="white" onClick={() => onDeleteSelection(index)}>
								<i className="bi-trash" />
							</Button>
							{/* <EllipsisButton>
                        <div className={"ellipsis-menu-item"} onClick={() => onAddSelection(index)}>
                            {t("aggregate_editor_page.selection_group_item_add_before")}
                        </div>
                        <div className={"ellipsis-menu-item"} onClick={() => onAddSelection(index + 1)}>
                            {t("aggregate_editor_page.selection_group_item_add_after")}
                        </div>
                    </EllipsisButton> */}
						</ButtonsContainer>
					</FormGroup>
				)}
			</Draggable>
		);
	}
);
